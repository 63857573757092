import "./App.css";
import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import disco_lab from "./disco_lab.png";
import disco_logo from "./disco_logo.png";
import disco_lab2 from "./disco_lab2.png";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  return (
    <div className="App">
      <Container>
        <header className="App-header">
          <img src={disco_logo} className="App-logo" alt="logo" />

          <img src={disco_lab} className="App-lab" alt="logo" />

          <img src={disco_lab2} className="App-lab" alt="logo" />
          <Card>Copyright Disco Extracts 2024</Card>
        </header>
      </Container>
    </div>
  );
}

export default App;
